<template>
  <div id="ErroAlert" v-if="visible && !this.$webtonative.isIosApp">
    <v-alert v-if="showCreditCardAlert" type="error" dense
      class="d-flex align-center flex-column flex-md-row justify-center text-center">
      Não foi possível processar o pagamento da sua assinatura. Entre em contato
      com nosso atendimento e evite o bloqueio do seu acesso.
      <span @click="getLink()" class="text-decoration-underline ml-2" style="cursor: pointer">
        Falar com suporte.
      </span>
    </v-alert>

    <v-alert v-if="showPixAlert" dense dismissible color="grey lighten-4" @input="handleClose()"
      class="d-flex align-center flex-column flex-md-row justify-center text-center">
      <template slot="close">
        <v-btn class="ml-2" icon small @click="handleClose">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
      {{ pixMessage }}
      <span class="text-decoration-underline ml-2" style="cursor: pointer" @click="openPixForm()"
        @input="handleClose()">
        Veja aqui como fazer o pagamento.
      </span>
    </v-alert>

    <template v-if="showEfiAlert">
      <v-alert v-if="!$vuetify.breakpoint.mobile" :type="efiAlertType"
        class="d-flex align-center flex-column flex-md-row justify-center text-center">
        {{ pixMessage }}
        <small class="text-primary ml-10" @click="handleSubscribe">
          Clique aqui para realizar o pagamento.
        </small>
      </v-alert>

      <v-alert v-if="$vuetify.breakpoint.mobile" color="grey lighten-4">
        <div class="d-flex align-center justify-center">
          <div>{{ pixMessage }}</div>
          <div>
            <app-btn to="/configuracoes/assinatura" color="primary" class="text-capitalize ml-md-8" v-text="'Ver'" />
          </div>
        </div>
      </v-alert>
    </template>

    <v-dialog width="500px" v-model="dialog">
      <v-card>
        <v-card-title>Pagamento Via pix</v-card-title>
        <v-card-text id="CardText">
          <v-row>
            <v-col>
              Para realizar o pagamento via pix, você deve enviar o valor de
              {{ $format.moneyBr(plan.value) }} para chave CNPJ:

              <!-- Abra seu aplicativo bancário e <b> escaneie o QR Code</b> para
              continuar com o pagamento -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>47.973.378/0001-35</h3>
              <br />
              <v-btn block color="primary" @click="handleCopy()">
                <v-icon left>mdi-content-copy</v-icon>
                Copiar
              </v-btn>
            </v-col>
          </v-row>
          <!-- 47973378000135 -->
          <!-- <v-row>
            <v-col>
              <div>
                <v-img
                  height="192"
                  width="192"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Link_pra_pagina_principal_da_Wikipedia-PT_em_codigo_QR_b.svg/800px-Link_pra_pagina_principal_da_Wikipedia-PT_em_codigo_QR_b.svg.png"
                />
              </div>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col class="text-center">
              <!-- <b>ou</b> <br /> -->
              <!-- <b> Copie e cole o código abaixo</b> no seu aplicativo bancário -->
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col>
              <v-btn color="success" >
                <v-icon left>mdi-content-copy</v-icon>
                <span>copiar código de cobrança</span>
              </v-btn>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      closed: false,
      dialog: false,
      pixKey: "47973378000135",
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    company() {
      return this.$store.state.auth.company;
    },

    lastSubscription() {
      return this.company.last_subscription;
    },

    plan() {
      return this.lastSubscription.plan;
    },

    isCreditCard() {
      return (
        this.lastSubscription.vendor == "stripe" ||
        this.lastSubscription.vendor == "pagarme"
      );
    },

    isPix() {
      return this.lastSubscription.vendor == "pix";
    },

    isEfi() {
      return this.lastSubscription.vendor == "efi";
    },

    showEfiAlert() {
      if (this.isEfi) {

        return (this.isEfi && this.lastSubscription.last_invoice.status == "waiting_payment" || this.lastSubscription.last_invoice.status == "past_due");
      }
    },

    isWaitingPayment() {
      return (
        this.showEfiAlert &&
        this.lastSubscription.last_invoice.status == "waiting_payment"


      );
    },

    isPastDue() {
      return (
        this.showEfiAlert &&
        this.lastSubscription.last_invoice.status == "past_due"


      );
    },

    isOwner() {
      return this.user.company_user.is_owner;
    },

    isSubscriptionPastDue() {
      return this.$store.getters["subscription/isSubscriptionPastDue"];
    },

    isFreeAccount() {
      return this.$store.getters["subscription/isFreeAccount"];
    },

    daysToEndSubscription() {
      return this.$store.getters["subscription/daysToEndSubscription"];
    },

    hasSubscriptionError() {
      return this.isSubscriptionPastDue || this.isSubscriptionEnded;
    },

    showCreditCardAlert() {
      return this.isCreditCard && this.isSubscriptionPastDue;
    },

    showPixAlert() {
      return this.isPix && this.daysToEndSubscription <= 2;
    },

    visible() {
      if (this.closed) {
        return false;
      }

      if (!this.isOwner) {
        return false;
      }

      if (this.isFreeAccount) {
        return false;
      }

      if (!this.lastSubscription) {
        return false;
      }

      if (this.isEfi) {
        return this.showEfiAlert;
      }

      return this.showCreditCardAlert || this.showPixAlert;
    },

    endsAtDate() {
      return this.$format.dateBr(this.lastSubscription.ends_at);
    },

    pixMessage() {
      if (this.isWaitingPayment && this.isEfi) {
        // return "Sua assinatura venceu! Realize o pagamento escaneando o QR code Pix";
        return `A sua fatura já está disponível! Realize o pagamento escaneando o QR code Pix até o dia ${this.endsAtDate}.`;

      }
      if (this.isPastDue && this.isEfi) {
        return "Sua assinatura venceu! Realize o pagamento escaneando o QR code Pix";

      }

      if (this.isWaitingPayment) {
        return `Existem cobranças pendentes em sua conta.`;
      }

      if (this.daysToEndSubscription > 0) {
        return `Sua assinatura vence em ${this.endsAtDate}.`;
      }
      if (this.daysToEndSubscription > 0) {
        return `Sua assinatura vence em ${this.endsAtDate}.`;
      }

      if (this.daysToEndSubscription == 0) {
        return `Sua assinatura vence hoje.`;
      }

      if (this.daysToEndSubscription < 0) {
        return `Sua assinatura venceu em ${this.endsAtDate}.`;
      }
    },
    efiAlertType() {

      var types = {
        past_due: "error",
        waiting_payment: "warning",
      };

      return types[this.lastSubscription.last_invoice.status];

      // return this.lastSubscription.last_invoice.status == "past_due"
      //   ? "error"
      //   : "warning";

      // return this.lastSubscription.last_invoice.status == "waiting_payment"
      //   ? "warning"
      //   : "success";
    },
  },

  methods: {
    handleClose() {
      this.closed = true;
    },

    openPixForm() {
      this.dialog = true;
    },

    handleCopy() {
      navigator.clipboard
        .writeText(this.pixKey)
        .then((response) => {
          this.$snackbar({
            message: "Chave copiada com sucesso!",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
        })
        .catch((error) => { });
    },
    handleSubscribe() {
      this.$router.push("/configuracoes/assinatura");
    },

    getLink() {
      let link = `https://api.whatsapp.com/send?phone=+${process.env.VUE_APP_FINANCIAL_SUPPORT_WHATSAPP}`;

      let msg = "&text=Olá, preciso de ajuda sobre o meu pagamento!";

      window.open(link + msg);
    },
  },
};
</script>

<style lang="sass">
#CardText
  //centralizar tudo no meio
  text-align: center

  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  //altura da tela

#ErroAlert
  .v-alert:not(.v-sheet--tile)
    border-radius: 0
    // background-color: #ff5252
    // color: #fff
</style>
