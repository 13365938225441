import Vue from 'vue'
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";


export default {
  data: () => ({
    customerVars: {
      vars: {
        "{nome_cliente}": "name",
        "{primeiro_nome_cliente}": (customer) => customer.name.split(' ')[0],

        "{nome_paciente}": "name",
        "{primeiro_nome_paciente}": (customer) => customer.name.split(' ')[0],

        "{cpf_cliente}": (customer) => Vue.$format.cpf(customer.cpf),
        "{cpf_paciente}": (customer) => Vue.$format.cpf(customer.cpf),
        "{rg_cliente}": 'rg',
        "{rg_paciente}": 'rg',
        '{cpf_responsavel_financeiro}': 'financial_responsible_cpf',
        '{nome_responsavel_financeiro}': 'financial_responsible_name',
        '{whatsapp_responsavel_financeiro}': 'financial_responsible_whatsapp',
        '{email_responsavel_financeiro}': 'financial_responsible_email',
        '{rg_responsavel_financeiro}': 'financial_responsible_rg',
      }
    },

    professionalVars: {
      //avaliable: ['receipt', 'session'],
      vars: {
        "{nome_profissional}": "name",
        "{primeiro_nome_profissional}": (professional) => professional.name.split(' ')[0],
        "{meu_contato}": 'phone_wa',
        "{registro_do_conselho}": 'council_registration',
        "{link_meu_contato}": (professional) => `https://wa.me/${professional.phone_wa?.replace(/[^\d]+/g, '')}`,

      }
    },

    sessionVars: {
      //avaliable: ['receipt'],
      vars: {
        "{numero_de_sessoes}": 'repetitions_count',
        "{numero_de_sessoes_extenso}": (session_group) => Vue.$format.extenso(session_group.repetitions_count, 'number'),
      }
    },

    transactionVars: {
      vars: {
        "{valor}": (transaction) => Vue.$format.moneyBr(transaction.value),
        "{valor_extenso}": (transaction) => Vue.$format.extenso(transaction.value),
        '{descricao}': (transaction) => transaction.description ? transaction.description.toLowerCase() : '',
        "{data_pagamento_extenso}": (transaction) => Vue.$format.dateBr(transaction.paid_at, "dd 'de' MMMM 'de' yyyy"),
        "{data_pagamento}": (transaction) => Vue.$format.dateBr(transaction.paid_at) ?? ' ....',
        '{detalhes}': 'details',
      }
    },

    commonVars: {
      vars: {
        "{data_de_hoje}": () => format(new Date(), "dd/MM/yyyy"),
        "{data_de_hoje_extenso}": () => `${format(new Date(), "dd")} de ${format(new Date(), "MMMM", { locale: ptBR })} de ${format(new Date(), "yyyy")}`,
      }
    }
  }),

  methods: {

    parseCommon(message) {
      for (let variable in this.commonVars.vars) {

        var regex = new RegExp(`\\s*{\\s*([a-zA-Z0-9_]+)\\s*}\\s*`, "g");
        message = message.replace(regex, "{$1}");

        let valueName = this.commonVars.vars[variable];

        if (typeof valueName == 'function') {
          var valueToReplace = valueName
        } else {

        }

        var regex = new RegExp(variable, "g");

        message = message.replace(regex, valueToReplace);
      }
      return message
    },

    parseCustomer(message, customer) {
      return this.parse(message, customer, this.customerVars.vars)
    },

    parseProfessional(message, professional) {
      return this.parse(message, professional, this.professionalVars.vars)
    },

    parseTransaction(message, transaction) {
      return this.parse(message, transaction, this.transactionVars.vars)
    },

    parseSessionGroup(message, session_group) {
      return this.parse(message, session_group, this.sessionVars.vars)
    },

    parse(message, data, vars) {

      var regex = new RegExp(`\\s*{\\s*([a-zA-Z0-9_]+)\\s*}\\s*`, "g");
      message = message.replace(regex, " {$1} ");

      for (let variable in vars) {

        let valueName = vars[variable];

        if (typeof valueName == 'function') {
          var valueToReplace = valueName(data)
        } else {
          var valueToReplace = data[valueName]
        }

        var regex = new RegExp(variable, "g");

        message = message.replace(regex, valueToReplace);
      }

      return message
    }
  }
}