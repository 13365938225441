var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"700px","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('app-title',[_vm._v("Confirmar Pagamento")])],1),_c('v-card-text',[_c('div',{staticClass:"text-right pb-6"},[_c('span',{staticClass:"text-h6",staticStyle:{"border":"1px solid #e0e0e0","padding":"15px","border-radius":"16px"}},[_vm._v(" Total: "+_vm._s(_vm.$format.moneyBr(_vm.totalSelected))+" ")])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transactions,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.date))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[_c('v-icon',{attrs:{"color":_vm.typeColor[item.type]},domProps:{"textContent":_vm._s(_vm.icons[item.origin][item.type])}}),_vm._v(" "+_vm._s(_vm.$format.moneyBr(Math.abs(item.value)))+" ")],1)]}}],null,true)})],1),_c('v-card-actions',[_c('app-back-btn',{on:{"click":function($event){_vm.dialog = false}}}),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-spacer'):_vm._e(),_c('app-confirm-btn',{on:{"click":function($event){return _vm.handlePay()}}},[_vm._v(" Confirmar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }