<template>
  <div>
    <v-row class="align-center">
      <v-col cols="12" md="4" class="d-flex">
        <app-text-search-field @input="handleSearchInput($event)" />
        <v-btn class="ml-2" @click="select()" large color="primary"><app-icon>search</app-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" text v-bind="attrs" v-on="on">
              <v-icon left>mdi-pencil</v-icon>
              Editar Colunas
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(allHeader, i) in allHeaders" :key="i">
              <v-list-item-title>{{ allHeader.text }}</v-list-item-title>
              <v-list-item-action>
                <v-checkbox v-model="headers" :value="allHeader" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <app-text-field v-model="searchParams.number" label="Número" />
      </v-col>
      <v-col cols="12" md="4">
        <app-text-field v-model="searchParams.company_id" label="company_id" />
      </v-col>
      <v-col cols="12" md="4">
        <app-select v-model="searchParams.type" label="Tipo" :items="types" clearable />
      </v-col>

      <v-col cols="12" md="4">
        <app-select :items="whatsapp_instances" v-model="searchParams.whatsapp_instance_id" item-text="name" clearable
          item-value="id" label="Instância WhatsApp" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-chip-group @change="select()" v-model="searchParams.status" column multiple>
          <template v-for="(status, index) in statusOptions">
            <v-chip filter :value="status.value" :key="index">
              {{ status.value }}
            </v-chip>
          </template>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <app-pagination @click="select($event)" :data="whatsAppNotifications">
          <v-data-table dense :headers="headers" :items="whatsAppNotifications.data" hide-default-footer disable-sort
            :items-per-page="whatsAppNotifications.per_page" :server-items-length="whatsAppNotifications.per_page">
            <template v-slot:[`item.whatsapp_status`]="{ item }">
              <v-icon> {{ getStatusIcon(item.whatsapp_status) }} </v-icon>
              {{ item.whatsapp_status }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-icon> {{ getStatusIcon(item.status) }} </v-icon>
              {{ item.status }}
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              {{ $format.dateTimeBr(item.created_at) }}
            </template>
          </v-data-table>
        </app-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      whatsapp_instances: [],
      showFilter: false,
      statusOptions: [
        {
          value: "willNotify",
          text: "será enviada",
          icon: "mdi-send",
          color: "gray",
        },

        {
          value: "error",
          text: "Erro ao enviar ",
          icon: "mdi-alert-circle",
          color: "error",
        },

        {
          value: "sending",
          text: "Enviando  ",
          icon: "mdi-clock-outline",
          color: "gray",
        },

        {
          value: "pending",
          text: "Enviando  ",
          icon: "mdi-clock-outline",
          color: "gray",
        },

        {
          value: "success",
          text: " enviada",
          icon: "mdi-check",
          color: "gray",
        },

        {
          value: "RECEIVED",
          text: "  recebida",
          icon: "mdi-check-all",
          color: "gray",
        },
        {
          value: "DELIVERY_ACK",
          text: "  recebida",
          icon: "mdi-check-all",
          color: "gray",
        },

        {
          value: "DELIVERY_ACK",
          text: "  recebida",
          icon: "mdi-check-all",
          color: "gray",
        },

        {
          value: "READ",
          text: "  lida",
          icon: "mdi-check-all",
          color: "info",
        },
        {
          value: "SENT",
          text: "  lida",
          icon: "mdi-send",
          color: "info",
        },
      ],

      headers: [
        { text: "id", value: "id", align: "center" },
        { text: "number", value: "number", align: "center" },
        { text: "whatsapp_status", value: "whatsapp_status", align: "center" },
        { text: "status", value: "status", align: "center" },
        { text: "created_at", value: "created_at", align: "center" },
      ],

      allHeaders: [
        { text: "whatsapp_id", value: "whatsapp_id", align: "center" },
        { text: "session_id", value: "session_id", align: "center" },
        { text: "message", value: "message", align: "center" },
        { text: "response", value: "response", align: "center" },
        { text: "updated_at", value: "updated_at", align: "center" },
      ],

      whatsAppNotifications: {},

      searchParams: {
        text: null,
        whatsapp_status: null,
        status: [
          "willNotify",
          "error",
          "sending",
          "pending",
          "success",
          "RECEIVED",
          "READ",
        ],
      },
      types: [
        { value: "welcome", text: "Boas vindas" },
        { value: "professional_day_schedule", text: "Agenda diária" },
        { value: "password_recovery", text: "Recuperação de senha" },

        { value: "event_reminder", text: "Lembrete de evento" },
        { value: "session_reminder", text: "Lembrete de sessão" },
        {
          value: "session_scheduling_request",
          text: "Solicitação de remarcação de sessão",
        },
        { value: "session_video_call_link", text: "Link de video chamada" },

        { value: "booking_created", text: "auto-agendamento criado" },
        { value: "booking_reminder", text: "Lembrete de auto-agendamento" },

        { value: "automatic_billing", text: "Cobrança automática" },
        { value: "manual_billing", text: "Cobrança manual" },
      ],
    };
  },
  mounted() {
    // this.select();
    this.getWhatsappApiInstances();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("admin/whatsapp-notifications", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.whatsAppNotifications = response.whatsAppNotifications;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },
    getWhatsappApiInstances() {
      this.$http
        .index("admin/whatsapp-instances")
        .then((response) => {
          this.whatsapp_instances = response.whatsapp_instances;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    getStatusIcon(status) {
      if (status != null) {
        return this.statusOptions.find((option) => {
          return option.value === status;
        }).icon;
      }

      if (status == null) {
        return "mdi-bug";
      }
    },
  },
};
</script>

<style></style>
