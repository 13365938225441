<template>
  <div id="alert-subscription">
    <v-alert v-model="visible" class="mb-2 mt-4 mx-6" dense>
      <div class="d-flex justify-space-between align-center pa-2">
        <div class="d-flex align-center">
          <div>
            <v-img height="35px" contain src="@/assets/images/subscription/illustration.svg" />
          </div>
          <div class="pl-4">
            <strong>
              Feito para
              <br />
              psicólogos e terapeutas
            </strong>
          </div>
        </div>
        <div>
          <strong class="primary--text">
            <template v-if="!isTrialEnded">
              <strong v-html="msgText" />
            </template>
            <template v-if="isTrialEnded">
              Seu período de teste terminou
            </template>
          </strong>
          <v-btn v-if="!this.$webtonative.isIosApp" to="/assinatura/checkout" color="primary" class="ml-md-6"
            elevation="0" rounded v-text="'Assine Já'" />
        </div>
      </div>
      <!-- <div class="d-flex align-center flex-column flex-md-row justify-center">
        <div>
          <template v-if="!isTrialEnded">
            <div v-html="msgText"></div>
          </template>
          <template v-if="isTrialEnded">
            Seu período de teste terminou
          </template>
        </div>
        <div>
          <v-btn
            to="/assinatura/checkout"
            color="primary"
            class=" ml-md-8"
            elevation="0"
            v-text="'Assine Já'"
          />
        </div>
      </div> -->
    </v-alert>
    <!-- <v-alert class="ma-0" v-model="visible" color="grey lighten-4" dense>
      <div class="d-flex align-center flex-column flex-md-row justify-center">
        <div>
          <template v-if="!isTrialEnded">
            <div v-html="msgText"></div>
          </template>
          <template v-if="isTrialEnded">
            Seu período de teste terminou
          </template>
        </div>
        <div>
          <v-btn
            to="/assinatura/checkout"
            color="primary"
            class=" ml-md-8"
            elevation="0"
            v-text="'Assine Já'"
          />
        </div>
      </div>
    </v-alert> -->

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="text-h5"> Período de Teste </v-card-title>

        <v-card-text class="d-flex flex-column">
          <div>
            <v-img height="200px" contain src="../../../assets/images/calendar.png" />
          </div>

          <template v-if="!isTrialEnded">
            <div class="mt-6 text-center" v-html="msgText"></div>

            <div class="mt-6 text-center" v-if="!isTrialEnded">
              Aproveite ao máximo seu período de testes! Cadastre seu primeiro
              paciente, crie um agendamento com pelo menos 24h de antecedência
              que a faremos nossa mágica!
            </div>
          </template>
          <template v-if="isTrialEnded">
            <div class="mt-8 text-center">Seu período de teste terminou</div>
          </template>
        </v-card-text>

        <v-card-actions class="d-flex flex-column" background-color="white">
          <v-btn v-if="!this.$webtonative.isIosApp" to="assinatura/checkout" color="primary" class="ml-md-8"
            elevation="0" block v-text="'Assine Já'" />
          <v-btn class="mt-4" block outlined color="primary" @click="dialog = false">
            Ir para o App
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),

  mounted() {
    if (this.$vuetify.breakpoint.mobile) {
      this.showDialogOnMobile();
    }
  },

  computed: {
    isFreeAccount() {
      return this.$store.getters["subscription/isFreeAccount"];
    },

    isOnTrial() {
      return this.$store.getters["subscription/isOnTrial"];
    },

    daysToEndTrial() {
      return this.$store.getters["subscription/daysToEndTrial"];
    },

    isTrialEnded() {
      return this.$store.getters["subscription/isTrialEnded"];
    },

    isFirstAccess() {
      return this.$store.state.app.firstAccess;
    },

    showMessage() {
      if (this.isOnTrial && !this.isFreeAccount && !this.isFirstAccess) {
        return true;
      }

      return false;
    },

    visible() {
      if (this.$vuetify.breakpoint.mobile) {
        return false;
      }

      return this.showMessage;
    },

    msgText() {
      if (this.daysToEndTrial > 5) {
        return (
          "<h3 class='secondary--text d-inline'>Você tem " +
          `${this.daysToEndTrial} dias` +
          " para experimentar o Psicoplanner</h3>"
        );
      }

      if (this.daysToEndTrial == 1) {
        return (
          "<span>Seu período de teste termina </span>" +
          `<b>Amanhã. </b>` +
          "<span>Não perca tempo, assine já!</span>"
        );
      }

      if (this.daysToEndTrial == 0) {
        return (
          "<span>Seu período de teste termina </span>" +
          `<b>Hoje. </b>` +
          "<span>Não perca tempo, assine já!</span>"
        );
      }

      return (
        "<span>Seu período de teste termina em </span>" +
        `<b>${this.daysToEndTrial} dias. </b>` +
        "<span>Não perca tempo, assine já!</span>"
      );
    },
  },

  methods: {
    showDialogOnMobile() {
      if (this.showMessage) {
        this.dialog = true;
      }
    },
  },
};
</script>

<style scoped lang="sass">
#alert-subscription
  div.v-alert
    background: linear-gradient(90deg, rgba(144, 127, 245, 0.32) 0%, rgba(144, 127, 245, 0.0768) 57.12%)
    border-radius: 32px
</style>