<template>
  <div>
    <template v-if="event.type == 'session'">
      <template v-if="origin == 'app'">
        <div class="d-flex justify-space-between">
          <div>
            <template v-if="calendarType == 'month'">
              <b>{{ $format.firstName(event.session.customer.name, 2) }}</b>
              <v-icon color="primary" small v-if="event.session.is_online_session">mdi-video</v-icon>
              <v-icon v-if="event.session.status !== 'pending'" small :color="statusIcon.color">{{ statusIcon.icon
                }}</v-icon>
              |
              {{ $format.time(event.session.start_at_time) }}
            </template>
            <template v-else>
              <div id="info">
                <b>{{ $format.firstName(event.session.customer.name, 2) }}</b>
                <v-icon small v-if="event.session.is_online_session">
                  mdi-video
                </v-icon>
                <v-icon v-if="event.session.status !== 'pending'" small :color="statusIcon.color">
                  {{ statusIcon.icon }}
                </v-icon>
              </div>
              <span>
                {{ $format.time(event.session.start_at_time) }} -
                {{ $format.time(event.session.end_at_time) }}
              </span>
            </template>
          </div>
          <div>
            <template v-for="(tag, index) of event.session.tags">
              <v-icon small :color="tag.color" :key="index">mdi-tag</v-icon>
            </template>
          </div>
        </div>
      </template>
      <template v-if="origin == 'booking'">
        <div class="d-flex justify-space-between">
          <div>
            <template v-if="calendarType == 'month'">
              <b>{{ $format.firstName(customerName, 2) }}</b>
              <v-icon v-if="event.session.is_online_session" color="primary" small>mdi-video
              </v-icon>
              <v-icon v-if="event.session.status !== 'pending'" :color="statusIcon.color" v-text="statusIcon.icon"
                small />
              |
              {{ $format.time(event.session.start_at_time) }}
            </template>
            <template v-else>
              <b>{{ $format.firstName(customerName, 2) }}</b>
              <v-icon small v-if="event.session.is_online_session">
                mdi-video
              </v-icon>
              <v-icon v-if="event.session.status !== 'pending'" small :color="statusIcon.color"
                v-text="statusIcon.icon" />
              <br />
              {{ $format.time(event.session.start_at_time) }} -
              {{ $format.time(event.session.end_at_time) }}
            </template>
          </div>
          <div>
            <v-icon small v-if="isBooking">mdi-tooltip-account</v-icon>

            <template v-for="(tag, index) of event.session.tags">
              <v-icon small :color="tag.color" :key="index">mdi-tag</v-icon>
            </template>
          </div>
        </div>
      </template>
    </template>
    <template v-if="event.type == 'holiday'">
      {{ event.name }}
    </template>
    <template v-if="event.type == 'custom_event'">
      <div class="d-flex justify-space-between">
        <div>
          <template v-if="calendarType == 'month'">
            <b>{{ event.name }}</b>

            {{ $format.time(event.session.start_at_time) }}
          </template>
          <template v-else>
            <b>{{ event.name }}</b>

            <br />
            {{ $format.time(event.session.start_at_time) }} -
            {{ $format.time(event.session.end_at_time) }}
          </template>
        </div>
        <div>
          <template v-for="(tag, index) of event.session.tags">
            <v-icon small :color="tag.color" :key="index">mdi-tag</v-icon>
          </template>
        </div>
      </div>
      <!-- <template v-if="calendarType == 'month'">
        <div class="pl-1">
          <b>{{ event.name }}</b> |
          {{ $format.time(event.session.start_at_time) }} -
          {{ $format.time(event.session.end_at_time) }}
        </div>
      </template>
      <template v-else>
        <div class="pl-1">
          <b>{{ event.name }}</b>
          <br />
          {{ $format.time(event.session.start_at_time) }} -
          {{ $format.time(event.session.end_at_time) }}
        </div>
      </template> -->
    </template>
  </div>
</template>

<script>
export default {
  props: {
    event: {},
    calendarType: {},
  },

  data: () => ({
    statusIcons: {
      missed: { icon: "mdi-close-thick", color: "red" },
      shown: { icon: "mdi-check-bold", color: "green" },
      suspended: { icon: "mdi-calendar-alert", color: "red" },
    },
  }),

  computed: {
    statusIcon() {
      return this.statusIcons[this.event.session.status];
    },

    origin() {
      return this.event.session.origin;
    },
    booking() {
      return this.event.session.booking;
    },
    isBooking() {
      return this.event.session.booking_id ? true : false;
    },
    customerName() {
      if (this.event.session.customer_id) {
        return this.event.session.customer.name;
      }

      if (this.event.session.booking_id) {
        return this.event.session.booking.name;
      }
    },
  },
};
</script>

<style lang="scss">
.v-event-timed {
  // min-height: 40px !important;
}

#info {
  height: 14px;
}
</style>