<template>
  <v-dialog v-model="dialog" persistent width="700px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title>
        <app-title>Confirmar Pagamento</app-title>
      </v-card-title>
      <v-card-text>

        <div class="text-right pb-6">
          <span class="text-h6" style="border: 1px solid #e0e0e0; padding: 15px; border-radius: 16px;">
            Total: {{ $format.moneyBr(totalSelected) }}
          </span>

        </div>



        <v-data-table :headers="headers" :items="transactions" :items-per-page="-1" hide-default-footer disable-sort
          dense>
          <template v-slot:[`item.description`]="{ item, index }">
            {{ item.description }}

          </template>
          <template v-slot:[`item.date`]="{ item, index }">
            {{ $format.dateBr(item.date) }}

          </template>
          <template v-slot:[`item.value`]="{ item, index }">
            <div>
              <v-icon v-text="icons[item.origin][item.type]" :color="typeColor[item.type]" />
              {{ $format.moneyBr(Math.abs(item.value)) }}
            </div>

          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handlePay()">
          Confirmar
        </app-confirm-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {


  data() {
    return {


      headers: [
        { text: "Descrição", value: "description" },
        { text: "Data", value: "date", align: "right" },
        { text: "Valor", value: "value", align: "right" },



      ],

      icons: {
        custom: {
          in: "mdi-plus",
          out: "mdi-minus",
        },
        session: {
          in: "mdi-plus",
        },
        sessionGroup: {
          in: "mdi-plus",
        },
      },

      typeColor: { in: "success", out: "error" },

      // baseForm: [

      // ],

      transactions: [],

      form: [],

      dialog: false,
    };
  },
  computed: {
    totalSelected() {
      return this.$calc.sum(this.transactions, "value");
    },

  },
  created() {
    this.reset();
  },

  methods: {
    open(data) {
      this.reset();
      this.setData(data);

      this.dialog = true;
    },

    reset() {
      // this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    setData(data) {
      this.transactions = JSON.parse(JSON.stringify(data));
      // this.transaction = data;
      // this.form = {
      //   transaction_id: data.id,
      //   phone_wa: this.setPhoneWa(data.session_group.customer),
      // };
    },



    getDescription(item) {
      if (item.customer_id == null) {
        return item.description;
      }

      return item.customer.name;
    },

    getSubDescription(item) {
      return item.customer_id != null ? item.description : "";
    },
    getValueClass(item) {
      if (item.status == "paid") {
        return item.value > 0 ? "success--text" : "error--text";
      }
      return "text--disabled";
    },

    handlePay() {
      this.$loading.start();
      this.$http.store("transactions/receive-all", { ids: this.transactions.map((item) => item.id) })
        .then((response) => {
          this.$loading.finish();
          this.$emit("store");
          this.dialog = false;

        })
        .catch((error) => {
          this.$loading.finish();
        });

    }
  },
};
</script>

<style></style>