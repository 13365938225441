<template>
  <div>
    <v-row>
      <v-col class="d-flex justify-start">
        <app-month-select @input="handleChangeMonth($event)" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1" cols="12" md="6">
        <app-text-search-field @input="handleSearchInput($event)" :isLoading="loading"
          @filter="showFilter = !showFilter" />
      </v-col>
      <v-col class="text-end">
        <app-btn v-if="canConfirmPayment" @click="confirmPayment()" color="primary"> Receber </app-btn>

        <app-btn v-if="canGenerateReceipt" @click="openDocumentForm()" color="primary"> Gerar Recibo </app-btn>
      </v-col>
    </v-row>
    <template v-if="showFilter">
      <v-row class="d-flex align-center">
        <v-col v-if="this.$acl.isClinic()" cols="12" md="4">
          <SelectProfessional v-model="searchParams.professional" clearable
            :disabled="this.$acl.forceFilterOwnTransactions()" @input="setProfessional($event), select()" />
        </v-col>
        <v-checkbox label="Apenas com recibo" v-model="searchParams.has_receipt" :checked-value="true"
          :unchecked-value="false" @change="select()" />
      </v-row>
      <v-row class="mb-2">
        <v-col cols="6" md="2">
          <app-date-picker @input="select()" v-model="searchParams.date_start" label="Data Inicial" />
        </v-col>
        <v-col cols="6" md="2">
          <app-date-picker @input="select()" v-model="searchParams.date_end" label="Data Final" />
        </v-col>
        <v-col cols="12" md="6">
          <v-chip-group @change="select()" v-model="searchParams.status" multiple>
            <v-chip color="success" small value="paid" filter> Pago </v-chip>
            <v-chip color="primary" small value="pending" filter> A Receber </v-chip>
            <v-chip color="error" small value="canceled" filter> Cancelado </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </template>

    <app-pagination @click="select($event)" :data="transactions">
      <TransactionsList v-if="$vuetify.breakpoint.mobile" :transactions="transactions"
        @click="openTransactionForm($event)" />

      <template v-if="!$vuetify.breakpoint.mobile">
        <div id="transactions-list-desktop">
          <br />
          <v-data-table v-model="selected" :headers="headers" :items="transactions.data" :items-per-page="-1"
            hide-default-footer @click:row="openTransactionForm" disable-sort dense show-select>
            <template v-slot:[`item.description`]="{ item, index }">
              <div @click="$emit('click', item)" :key="index">
                <div class="item">
                  <div class="pr-4">
                    <v-icon v-text="icons[item.origin][item.type]" :color="typeColor[item.type]" />
                  </div>
                  <div class="descriptions">
                    <div class="body-04">
                      {{ $format.dateBr(item.date) }}
                    </div>
                    <div class="body-02 my-2">
                      {{ getDescription(item) }}
                    </div>
                    <div class="body-03">
                      {{ getSubDescription(item) }}
                    </div>
                  </div>
                  <div class="ml-auto totals">
                    <span :class="getValueClass(item)" v-text="$format.moneyBr(Math.abs(item.value))" />
                    <app-transaction-status-chip hide-icon :transaction="item" />
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
        </div>
      </template>
    </app-pagination>

    <TransactionForm @store="handleStore()" ref="TransactionForm" />

    <StoreDocumentForm ref="StoreDocumentForm" documentType="receipt" :customer="customer" @store="handleStore()"
      :session_group="getSelectedSessionGroups()" :transaction="getSelectedTransactions()" />

    <PayCustomerTransactions ref="PayCustomerTransactions" @store="processRefresh()" />
  </div>
</template>

<script>
import TransactionForm from "@/components/transactions/form/TransactionForm.vue";
import SelectProfessional from "@/components/app/forms/SelectProfessional.vue";
import StoreDocumentForm from "@/components/customers/sections/StoreDocumentForm.vue";
import TransactionsMixin from "@/mixins/Transactions/TransactionsMixin.js";
import TransactionsList from "@/components/transactions/lists/TransactionsList.vue";
import PayCustomerTransactions from "@/components/transactions/sections/PayCustomerTransactions.vue";

export default {
  components: {
    TransactionForm,
    TransactionsList,
    SelectProfessional,
    StoreDocumentForm,
    PayCustomerTransactions
  },

  props: {
    customer: {},
  },
  mixins: [TransactionsMixin],

  data() {
    return {
      selected: [],

      headers: [{ text: "Descrição", value: "description" }],

      icons: {
        custom: {
          in: "mdi-plus",
          out: "mdi-minus",
        },
        session: {
          in: "mdi-plus",
        },
        sessionGroup: {
          in: "mdi-plus",
        },
      },

      typeColor: { in: "success", out: "error" },
    };
  },

  computed: {
    canGenerateReceipt() {
      var allPaid = this.selected.every((item) => item.status == "paid");

      var isMobile = this.$vuetify.breakpoint.mobile;

      return allPaid && this.selected.length > 0 && !isMobile;
    },

    canConfirmPayment() {
      var allPPending = this.selected.every((item) => item.status == "pending");

      var isMobile = this.$vuetify.breakpoint.mobile;

      return allPPending && this.selected.length > 0 && !isMobile;
    },
  },

  watch: {
    customer() {
      this.setCustomer(this.customer);
    },
  },

  // watch: {
  //   customer: {
  //     handler() {
  //       this.setCustomer(this.customer);
  //     },
  //     deep: true,
  //   },
  // },

  methods: {
    openDocumentForm() {
      this.$refs.StoreDocumentForm.open();
    },

    openTransactionForm(transaction) {
      var professional_id = transaction.session_group
        ? transaction.session_group.professional_id
        : null;

      if (this.$acl.canUpdateTransaction(professional_id)) {
        this.$refs.TransactionForm.open(transaction.id);
      }
    },

    handleStore() {
      this.select();
      this.$emit("store");
    },

    getDescription(item) {
      if (item.customer_id == null) {
        return item.description;
      }

      return item.customer.name;
    },

    getSubDescription(item) {
      return item.customer_id != null ? item.description : "";
    },

    getValueClass(item) {
      if (item.status == "paid") {
        return item.value > 0 ? "success--text" : "error--text";
      }
      return "text--disabled";
    },

    getSelectedSessionGroups() {
      var count = this.selected.reduce((acc, item) => {
        if (item.origin == "sessionGroup") {
          acc += item.session_group.repetitions_count;
        }

        if (item.origin == "session") {
          acc += 1;
        }

        return acc;
      }, 0);
      return { repetitions_count: count };
    },

    getSelectedTransactions() {
      if (this.selected.length == 0) {
        return null;
      }
      return {
        value: this.$calc.sum(this.selected, "value"),
        paid_at: this.getSelectedPaidAt(),
        details: this.getSelectedDetails(),
      };
    },

    getSelectedDetails() {
      if (this.selected.length == 0) {
        return null;
      }

      return this.selected.map((item) => item.description).join(", <br>");
    },

    getSelectedPaidAt() {
      return this.selected.every(
        (item) => item.paid_at === this.selected[0].paid_at
      )
        ? this.selected[0].paid_at
        : null;
    },

    confirmPayment() {
      this.$refs.PayCustomerTransactions.open(this.selected);
    },

    processRefresh() {
      this.selected = []
      this.handleStore()
    }

  },
};
</script>


<style lang="sass">
#transactions-list-desktop

  .body-02
    color: #624DE3
    font-size: 16px
    font-family: Poppins
    font-style: normal
    font-weight: 500
    line-height: 24px

  .body-03
    color: #8C8A97
    font-size: 14px
    font-family: Poppins
    font-style: normal
    font-weight: 500
    line-height: 21px

  .body-04
    color: #AAA8B8
    font-size: 12px
    font-family: Poppins
    font-style: normal
    font-weight: 500
    line-height: 18px
    letter-spacing: 1px

  .item
    display: flex
    flex-grow: 0.6
    align-items: center
    padding: 8px
    cursor: pointer
</style>