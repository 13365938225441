<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title> Novos agendamentos </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group>
              <template v-for="booking in unreadBookings">
                <v-divider></v-divider>
                <v-list-item class="py-4" @click="openSession(booking)">
                  <v-list-item-content>
                    <div class="d-flex align-center">
                      <div class="pr-4" style="border-right: 2px solid #ccc">
                        <div class="mb-4">
                          <b>{{ $format.smallDateBR(booking.start_at) }}</b>
                        </div>
                        <div>
                          <b>{{ $format.time(booking.start_at_time) }}</b>
                        </div>
                      </div>
                      <div class="pl-4">
                        <div class="mb-2">
                          <span>{{ booking.name }}</span>
                        </div>
                        <small class="text--disabled">
                          Cpf: {{ booking.cpf }}</small>
                        <br />
                        <small class="text--disabled">Whatsapp: {{ booking.cpf }}</small>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="handleClose()">Voltar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SessionDialog ref="SessionDialog" @store="handleStore()" />


  </div>

</template>

<script>
import SessionDialog from "@/components/schedule/sections/SessionDialog.vue";

export default {
  props: {
    unreadBookings: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    SessionDialog,
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    async open() {
      this.dialog = true;
    },

    handleClose() {
      this.$emit("close");
      this.dialog = false;
    },

    openSession(booking) {

      this.$refs.SessionDialog.open(booking.session.id);

      this.markBookingAsRead(booking.id);

    },

    async markBookingAsRead(id) {
      await this.$http.update("sessions/unread-booking", id)
        .then(() => this.$emit("refresh"));
    },
  },
};
</script>